.flex {
    display: flex;
}

.button-minified {
    height: min-content;
}

.centered-vertical {
    align-items: center;
}

.centered-horizontal {
    text-align: center;
}

.spaced-between {
    justify-content: space-between;
}

.minified {
    height: min-content;
}

.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.date-range {
    cursor: pointer;
    border: 2px solid #CCCCCC;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
}

.date-picker-icon {
    color: #CCCCCC;
}

.datepicker-holder {
    position: relative;
}

.datepicker {
    position: absolute;
    right: 0;
    z-index: 50;
}
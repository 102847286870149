.scroll {
    margin-top: 20px;
    height: 500px;
    overflow-y: scroll;
}

.compact {
    margin: 0;
    width: 200px;
}

.header {
    display:flex;
    flex-direction: row;
    align-items: center;
}
@import '../node_modules/handsontable/dist/handsontable.full.css';

* {
  font-family: 'SF Pro Display', sans-serif;
}

.bg-attention {
  background-color: #EE6352!important;
}

.bg-warning {
  background-color: #ffeeba!important;
}

.bg-success {
  background-color: #c3e6cb!important;
}

.bg-danger {
  background-color: #f5c6cb!important;
}

.bg-secondary {
  background-color: #e2e3e5!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navigation {
  padding: 0;
  background: #F6F5FA;
}

.navigation .list-group-item {
  font-size: 24px;
  line-height: 29px;
  border: 0;
  padding: 25px 10px 25px 30px;
  color: #2B2F42;
  border-right-color: rgba(110, 110, 255, 0.2);
  background-color: inherit;
  transition: background 0.5s ease;
  transition: border-right-color 0.5s ease;
}

.navigation .list-group-item:hover {
  background: rgba(110, 110, 255, 0.2);
  border-right: 4px solid #6E6EFF;
}

.logo {
  padding: 90px 20px;
}

.logo, .site-icon, .site-icon svg, .site-name {
  display: inline-flex;
}

.site-icon svg {
  margin: auto;
  align-items: center;
}

.site-icon {
  width: 40px;
  height: 40px;
  background: #6E6EFF;
  border-radius: 10px;
}

.site-name {
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  margin-left: 20px;
  line-height: 40px;
  color: #2B2F42;
}

.content {
  padding: 100px;
}

.quick-links {
  padding: 40px 50px;
  border-radius: 30px;
}

.quick-links h2 {
  font-weight: 700;
  margin-bottom: 30px;
}

.quick-links ul {
  padding-left: 0!important;
}

.quick-links ul li {
  padding: 15px 0;
}

.quick-links .list-group-item {
  border: 0px;
}
.quick-links span {
  font-size: 24px;
}
.quick-links a {
  color: #2B2F42;
  text-decoration: underline;
}

.overview-card {
  border-radius: 10px;
  width: 100%;
  height: 140px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: end;
  position: relative;
}

.overview-card>* {
  display: flex;
  margin: 0;
}

.apps-published {
  background: #40CC67;
  color: #FFFFFF;
}

.apps-moderation {
  background: #FAC05E;
  color: #FFFFFF;
}

.apps-draft {
  background: #F6F5FA;
  color: #6E6EFF;
}

.apps-banned {
  background: #F6F5FA;
  color: #6E6EFF;
}

.apps-attention {
  background: #FF5353;
  color: #FFFFFF;
}

.apps-archive {
  background: #F6F5FA;
  color: #6E6EFF;
}
.apps-ads {
  background: #6E6EFF;
  color: #FFFFFF;
}

.overview-card h1 {
  font-size: 48px;
  font-weight: bold;
  line-height: normal;
}

.overview-card h4 {
  font-size: 18px;
  font-weight: 300;
}

.overview-card img {
  display: block;
  width: 30px;
  height:auto;
  position: absolute;
  right: 25px;
  top: 15px;
}

.navigation ul a img {
  width: 30px;
  height: auto;
  margin-right: 30px;
}

.navigation ul a:hover img {
  fill: darkred;
}
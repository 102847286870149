.button-create-project {
    margin-left: 20px;
}

.cards-panel {
    margin-top: 40px;
}

.chart {
    margin-top: 40px;
}

.button-logout-holder {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.data-card {
    width: min-content;
    height: min-content;
    padding: 20px 40px 20px 40px;
}

.data-card-coast {
    font-size: 18pt;
}

.data-card-green {
    height: 135px;
    background-color: #28A745;
    color: #FFFFFF;
}

.data-card-yellow {
    height: 135px;
    background-color: #FFF3CD;
    color: #856404;
}

.data-card-blue {
    height: 135px;
    background-color: #CCE5FF;
    color: #004085;
}

.data-card-green-light {
    height: 135px;
    background-color: #D4EDDA;
    color: #155724;
}

.data-card-green-medium {
    height: 135px;
    background-color: #83e5b5;
    color: #155724;
}

.data-card-red {
    height: 135px;
    background-color: #F8D7DA;
    color: #721C24;
}

.data-card-grey {
    height: 135px;
    color: #000000;
    background-color: #CCCCCC;
}
.sub-day {
    margin-top: 10px;
}

.month {
    font-size: 15pt;
}

.month-selector {
    cursor: pointer;
}
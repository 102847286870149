.arrow-back {
    color: #2F80ED;
    font-size: 25pt;
}

.icon-settings {
    color: #828282;
    font-size: 20pt;
    margin-top: 5px;
}

.icon-settings:hover {
    cursor: pointer;
    color: #666666;
}

.cards-panel {
    margin-top: 40px;
    justify-content: space-between;
}

.buttons-panel {
    margin-top: 20px;
}

.button-project {
    margin-left: 20px;
}

.chart {
    margin-top: 40px;
}

.menu {
    position: absolute;
    right: 0;
    z-index: 60;
    width: 200px;
}

.menu-holder {
    position: relative;
}
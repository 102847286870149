.projects-table-header {
    background-color: #CCE5FF;
    cursor: pointer;
}

.project-row-green {
    background-color: #D4EDDA;
}

.project-row-yellow {
    background-color: #FFF3CD;
}

.project-row-red {
    background-color: #F8D7DA;
}

.table-projects {
    margin-top: 40px;
}

.project-link {
    text-decoration: none;
}

.icon-view {
    cursor: pointer;
    color: #CCCCCC;
}

.icon-view-selected {
    cursor: pointer;
    color: #000000;
}